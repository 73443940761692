// extracted by mini-css-extract-plugin
export var block = "newFooter-module--block--dHZB+";
export var br = "newFooter-module--br--FEQFX";
export var button = "newFooter-module--button--NRhyB";
export var description = "newFooter-module--description--gLlVg";
export var footer = "newFooter-module--footer--jdgmC";
export var halvaDlyagamerov = "newFooter-module--halvaDlyagamerov--Kd8s1";
export var hide = "newFooter-module--hide--FXUVG";
export var redButton = "newFooter-module--redButton--W55NH";
export var seoPromo = "newFooter-module--seoPromo--1F8K1";
export var text = "newFooter-module--text--aTawy";
export var wrapperLarge = "newFooter-module--wrapperLarge--wrxNT";
export var wrapperSmall = "newFooter-module--wrapperSmall--VZu+e";